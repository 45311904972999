import React from 'react';
import { Route } from 'react-router-dom';

const RouteWrapper = ({ component, path, exact = false }) => {
  const routeComponent = (props) => React.createElement(component, props);

  return (
    <Route path={path} exact={exact} render={routeComponent} />
  );
};

export default RouteWrapper;
