import React from 'react';
import Routes from './routes';
import { HelmetProvider } from 'react-helmet-async'

import './styles/globals.css';

function App() {
  const helmetContext = {}

  return (
    <HelmetProvider context={helmetContext}>
      <Routes />
    </HelmetProvider>
  );
}

export default App;
